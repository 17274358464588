var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "imgZoomed" },
      [
        _c("inner-image-zoom", {
          attrs: {
            src: _vm.selectedImageUrl,
            zoomType: "click",
            hasSpacer: true,
            zoomScale: 1.2,
            fullscreenOnMobile: true,
            mobileBreakpoint: 767,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "imageThumbnails" },
      [
        _vm._l(
          _vm.images.filter((x) => x.url != null && !x.url.includes(".pdf")),
          function (image, imageIndex) {
            return _c(
              "div",
              {
                key: imageIndex,
                staticClass: "imageThumbnail",
                class: { selected: imageIndex === _vm.selectedImageIndex },
                on: {
                  click: function ($event) {
                    _vm.selectedImageIndex = imageIndex
                  },
                },
              },
              [
                [
                  _c("img", {
                    attrs: { src: _vm.getImageUrl(image) },
                    on: {
                      error: function ($event) {
                        return _vm.onImageError(image.url)
                      },
                    },
                  }),
                ],
              ],
              2
            )
          }
        ),
        _vm.youtubeVideoId
          ? _c("div", { staticClass: "videoContent" }, [
              _c(
                "a",
                {
                  staticClass: "mediabox",
                  attrs: {
                    href: `https://www.youtube.com/watch?v=${_vm.youtubeVideoId}`,
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: `https://img.youtube.com/vi/${_vm.youtubeVideoId}/sddefault.jpg`,
                    },
                  }),
                ]
              ),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }