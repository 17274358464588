<!-- eslint-disable -->
<template>
  <div>
    <div v-show="isLoading" class="loadingProduct">
      <img src="/images/triangle-loading.gif" />
    </div>
      <Info-box-to-external-provider v-show="!isLoading && itemsContainer.items && enableExternalProviderInfoBox && itemsContainer.items.length > 0" :items-container.sync="itemsContainer"></Info-box-to-external-provider>
      <div v-show="!isLoading && itemsContainer.items && itemsContainer.items.length > 0" class="itemsContainer" :class="{'noInfoBox' : !isExternalProviderInfoBoxShown}" ref="itemsProducts">
        <template v-if="hasCta">
          <div class="first">
            <template v-for="item in take(itemsContainer.items, 8)">
              <slot :member="memberId" :item="item" :isFavorite="favorites.some(x => x.productID.includes(item.identifier) ? true : false)" />
            </template>
          </div>
          <slot name="cta" />
          <div class="second">
            <template v-for="item in skip(itemsContainer.items, 8)">
              <slot :member="memberId" :item="item" :isFavorite="favorites.some(x => x.productID.includes(item.identifier) ? true : false)" />
            </template>
          </div>
        </template>
        <template v-else v-for="item in itemsContainer.items">
          <slot :member="memberId" :item="item" :isFavorite="favorites.some(x => x.productID.includes(item.identifier) ? true : false)" />
        </template>
      </div>
    <div v-show="!isLoading && itemsContainer.items && itemsContainer.items.length === 0">
      {{ $t('noResults') }}
    </div>
  </div>
</template>
<!-- eslint-enable -->

<script>
import hashMixins from '@/hashMixins';
import { tryParseInt } from '@/functions';
import { getProductFacets, getProductItemFacets } from '@/helpers/searchHelper';
import { Sort } from '@/sortingEnum';
import stores from '@/models/store/Store';
import InfoBoxToExternalProvider from '@/components/UI/InfoBoxToExternalProvider';

export default {
  mixins: [hashMixins],
  components: {
    InfoBoxToExternalProvider
  },
  props: {
    enableExternalProviderInfoBox: {
      type: Boolean,
      default: false
    },
    itemsContainer: {
      type: Object,
      required: true
    },
    defaults: {
      type: Object,
      default: { count: 32, sort: 5, store: stores.DR, isDesc: true }
    },
    addParamsToUrl: {
      type: Boolean,
      default: true
    },
    scrollTopElement: {
      type: String,
      default: null
    },
    memberId: {
      type: Number,
      default: null
    },
    useFiltrationFacets: {
      type: Boolean,
      default: false 
    }
  },
  data() {
    return {
      isLoading: true,
      oldParams: {},
      params: {},
      hashParams: {},
      favorites: [],
      infoBoxMinorCategories: ['Bardeaux à toiture Duration'],
      filtrationFacetsLoaded: false
    };
  },
  computed: {
    hasCta() {
      return !!this.$slots.cta;
    },
    isExternalProviderInfoBoxShown() {
      if (this.itemsContainer.selectedFacets.minorCategories != null) {
        return this.itemsContainer.selectedFacets.minorCategories.some(x => this.infoBoxMinorCategories.includes(x))
      } else {
        return false
      }
    }
  },
  async created() {
    this.itemsContainer.selectedFacets.count = this.defaults.count;
    this.itemsContainer.selectedFacets.index = this.defaults.index;
  },
  async mounted() {
    const isQueryAfterParamsUri = location.href.includes('?') && location.href.includes('#') && location.href.indexOf('?') > location.href.indexOf('#')
    if (isQueryAfterParamsUri) {
      let uri = location.href;
      const paramsString = location.href.substring(uri.indexOf('#'), uri.indexOf('?'));
      uri = uri.replace(paramsString, '');
      uri = uri + paramsString;
      location.href = uri;
    }
    this.$root.$on('scroll', this.scrollToTop);
    if (this.memberId) {
      await this.getUserFavorites()
    }
  },
  beforeDestroy() {
    this.$root.$off('scroll', this.scrollToTop);
  },
  methods: {
    async getUserFavorites() {
      try {
        const response = await this.$axios.get(`/Umbraco/Api/Favorites/GetFavorites?memberid=${this.memberId}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
          }
        });
        this.favorites = response.data;
      } catch(e) {
        // Fait rien
      }
    },
    async loadApi(headers = { culture: 'fr-CA' }) {
      this.isLoading = true;
      this.params = { ...this.defaults, ...this.itemsContainer.selectedFacets };
      this.params.count = tryParseInt(this.params.count, 16);
      this.params.index = tryParseInt(this.params.index, 0);
      this.params.sort = tryParseInt(this.params.sort, null);

      if (JSON.stringify(this.params) !== JSON.stringify(this.oldParams)) {
        this.hashParams = this.getHashParams();
        this.hashParams.count = this.params.count;

        if (this.params.index >= 0) {
          this.hashParams.index = this.params.index;
        } else {
          /* eslint-disable-next-line no-unused-vars */
          const { index, ...remainingParams } = this.hashParams;
          this.hashParams = remainingParams;
        }

        if (this.params.sort) {
          this.hashParams.sort = this.params.sort;
        } else {
          /* eslint-disable-next-line no-unused-vars */
          const { sort, ...remainingParams } = this.hashParams;
          this.hashParams = remainingParams;
        }

        if (this.params.isDesc === true || this.params.isDesc === false) {
          this.hashParams.isDesc = this.params.isDesc;
        } else {
          /* eslint-disable-next-line no-unused-vars */
          const { isDesc, ...remainingParams } = this.hashParams;
          this.hashParams = remainingParams;
        }

        if (this.params.brands && this.params.brands.length) {
          if (this.params.brands[0] === '') {
            this.params.brands[0] = 'Autres';
          }
          this.hashParams.brands = this.stringifyList(this.params.brands);
        } else {
          /* eslint-disable-next-line no-unused-vars */
          const { brands, ...remainingParams } = this.hashParams;
          this.hashParams = remainingParams;
        }

        if (this.params.majorCategory && this.params.majorCategory.length) {
          this.hashParams.majorCategory = this.params.majorCategory;
        } else {
          /* eslint-disable-next-line no-unused-vars */
          const { majorCategory, ...remainingParams } = this.hashParams;
          this.hashParams = remainingParams;
        }

        if (this.params.intermediateCategory && this.params.intermediateCategory.length) {
          this.hashParams.intermediateCategory = this.params.intermediateCategory;
        } else {
          /* eslint-disable-next-line no-unused-vars */
          const { intermediateCategory, ...remainingParams } = this.hashParams;
          this.hashParams = remainingParams;
        }

        if (this.params.minorCategories && this.params.minorCategories.length) {
          this.hashParams.minorCategories = this.stringifyList(this.params.minorCategories);
        } else {
          /* eslint-disable-next-line no-unused-vars */
          const { minorCategories, ...remainingParams } = this.hashParams;
          this.hashParams = remainingParams;
        }

        if (this.params.promotionTags && this.params.promotionTags.length) {
          this.hashParams.promotionTags = this.stringifyList(this.params.promotionTags);
        } else {
          /* eslint-disable-next-line no-unused-vars */
          const { promotionTags, ...remainingParams } = this.hashParams;
          this.hashParams = remainingParams;
        }

        if (this.params.search) {
          this.hashParams.search = this.params.search;
        }
        
        if (this.addParamsToUrl) {
          this.setHashParams(this.hashParams);
        }

        if (this.params.index && this.params.index === this.oldParams.index) {
          /* eslint-disable-next-line no-unused-vars */
          const { index, ...newParams } = params;
          this.params = newParams;
          this.$root.$emit('resetPage');
        }

        const filters = [];

        if (this.params.intermediateCategory && this.params.intermediateCategory.length > 0) {
          filters.push({ field: 'intermediateCategory', value: this.params.intermediateCategory, type: 'any' });
        }
        if (this.params.majorCategory && this.params.majorCategory.length > 0) {
          filters.push({ field: 'majorCategory', value: this.params.majorCategory, type: 'any' });
        }      
        if (this.params.minorCategories && this.params.minorCategories.length > 0) {
          filters.push({ field: 'minorCategory', value: this.params.minorCategories, type: 'any' });
        }
        if (this.params.brands && this.params.brands.length > 0) {
          if (this.params.brands[0] === 'Autres') {
            this.params.brands[0] = '';            
          } 
          filters.push({ field: 'brand', value: this.params.brands, type: 'any' });
        }
        if (this.params.promotionTags && this.params.promotionTags.length > 0) {
          filters.push({ field: 'promotionTag', value: this.params.promotionTags, type: 'any' });
        }
        if (this.params.hasPromotion === true || this.params.hasPromotion === false) {
          filters.push({ field: 'hasPromotion', value: this.params.hasPromotion ? 1 : 0, type: 'all' });
        }
        if (this.params.excludedID) {
          filters.push({ field: 'identifier', value: this.params.excludedID, type: 'none' });
        }
        if (this.params.similarProductSkus && this.params.similarProductSkus.length > 0) {
          filters.push({ field: 'sku', value: this.params.similarProductSkus, type: 'any' });
        }

        let sort = { '_score': 'desc' }; // On tri par le meilleur score si non spécifié
        if (this.params.sort === Sort.Name) {
          sort = { 'name': this.params.isDesc ? 'desc' : 'asc' };
        } else if (this.params.sort === Sort.Price) {     
          sort = { 'price.bestPrice': this.params.isDesc ? 'desc' : 'asc' };
        } else if (this.params.sort === Sort.Date) {
          sort = { 'modificationDate': this.params.isDesc ? 'desc' : 'asc' };
        } else if (this.params.sort === Sort.Score) {
          sort = { '_score': this.params.isDesc ? 'desc' : 'asc' };
        }

        await this.$root.ensureHiddenCategoriesAreLoaded();

        const response = await this.$root.sendAppSearchRequest({
          facets: this.$root.getAppSearchFacets(),
          filters: {
            ...this.$root.getAppSearchFilters(filters),
          },
          page: {
            current: this.params.index / this.params.count + 1, // AppSearch commence sa pagination à 1
            size: this.params.count
          },
          sort,
          query: this.params.search || ''
        });

        if (this.useFiltrationFacets && !this.filtrationFacetsLoaded) {
          this.itemsContainer.filtrationFacets = getProductFacets(response.facets);
          this.filtrationFacetsLoaded = true;
        }
        this.itemsContainer.items = getProductItemFacets(response.results, this.$root.getStore());
        this.itemsContainer.facets = getProductFacets(response.facets);
        this.itemsContainer.total = response.meta.page.total_results;
        this.oldParams = this.params;
        this.itemsContainer.selectedFacets = this.params;
        this.$emit('dataChanged');
      }
      this.isLoading = false;
    },
    scrollToTop() {
      const element = this.scrollTopElement ? document.querySelector(this.scrollTopElement) : null;
      if (element) {
        document.body.scrollTop = element.offsetTop;
        document.documentElement.scrollTop = element.offsetTop;
      }
    },
    take(array, quantity) {
      return array.filter((_, index) => index < quantity);
    },
    skip(array, quantity) {
      return array.filter((_, index) => index >= quantity);
    },
    sendViewItemListGaEvent() {
      try {
        if (dataLayer) {
          dataLayer = dataLayer || [];
          dataLayer.push({
            event: 'view_item_list', 
            ecommerce: {                          
              items:  this.itemsContainer.items.map(item => ({                         
                item_name: item.name,           
                item_id: item.sku
              }))
            }
          });
        }
      } catch { /*Fait rien*/ }
    }
  },
  watch: {
    async 'itemsContainer.selectedFacets'(value, oldValue) {
      if (value.brands !== oldValue.brands ||
        value.count !== oldValue.count ||
        value.majorCategories !== oldValue.majorCategories ||
        value.intermediateCategories !== oldValue.intermediateCategories ||
        value.minorCategories !== oldValue.minorCategories ||
        value.PromotionTag !== oldValue.PromotionTag ||
        value.sort !== oldValue.sort) {
        value.index = 0;
        this.$root.$emit('resetPage');
      }

      if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
        await this.loadApi();
      }
    },
    async 'itemsContainer.items'() {
      if (this.itemsContainer.items) {
        this.sendViewItemListGaEvent();
      }
    }
  }
};
</script>
